.App {
  text-align: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fullscreen-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -2;
}

.container {
  position: absolute;
  width: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  height: 100%;
  margin-top: 100px;
  pointer-events: auto;
  z-index: 2;
}

.header {
  margin-top: 0;
  text-align: left;
  padding-left: 0;
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: flex-start;
}

.header-content h1 {
  margin-bottom: 0;
}

.header-content h4 {
  margin-top: 0;
}

.container {
  position: absolute;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  height: 100%;
  pointer-events: auto;
  z-index: 2;
}

/* Mobile */
@media screen and (max-width: 480px) {
  .container {
    width: 75%;
    top: 40%;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 30%;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 22%;
  }
}

/* .stack-layer1 {
  width: 100%;
} */

.container a {
  color: #0066cc; /* Default browser link blue */
  text-decoration: underline;
  transition: opacity 0.3s ease;
}

.container a:visited {
  color: #551A8B; /* Default browser visited link color */
}

.container a:hover {
  opacity: 0.7;
  color: #0056b3; /* Slightly darker blue on hover */
}

.container ul {
  list-style-type: none;
  padding-left: 20px;
}

.container li {
  margin: 8px 0;
}

.choice-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: flex-start;
}

.bubble-choice {
  background: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  border-radius: 0;  /* Remove rounded corners */
}

.bubble-choice:hover {
  background: white;
  color: black;
}

.bubble-choice.active {
  background: white;
  color: black;
}

.bubble-choice:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #666;
  color: #666;
}

.bubble-choice:disabled:hover {
  background: transparent;
  color: #666;
}



